import React from 'react'

import Layout from '../components/layout'
import Headline from '../elements/headline'
import Seo from '../components/seo'

const PrivacyScreen = () => (
  <Layout>
    <Seo title="DSGVO / GDPR" />
    <section
      className="
        bg-blue-100
        flex
        flex-col
        items-center
        justify-center
        mt-16
        px-16
        py-48
        text-center
        w-full
      "
    >
      <p
        className="
          font-mono
          text-blue-900
        "
      >Last Modified: 2018-06-14</p>
      <Headline level={1}>DSGVO / GDPR</Headline>
      <Headline level={3}>Information for Podcasters / Users of Podlove Publisher – What User Data is used or accessible to other services?</Headline>
    </section>
    <section
      className="
        flex
        flex-wrap
        items-stretch
        justify-center
        px-4
        mb-10
        md:px-0
      "
    >
      <div
        className="
          markdown
          ml-auto
          mr-auto
          mt-16
          max-w-2xl
        "
      >
        <p>Both <strong>Podlove Subscribe Button</strong> and <strong>Podlove Web Player</strong> may be served via <a href="https://www.keycdn.com/" target="_blank" rel="noreferrer">keycdn.com</a> (<a href="https://www.keycdn.com/gdpr" target="_blank" rel="noreferrer">keycdn GDPR statement</a>). You can choose to not use our CDN to serve these applications in the module settings.</p>
        <p>If <strong>Tracking/Analytics</strong> is enabled, user IP addresses are used to determine their geographic location. IP addresses are not saved, but anonymized and then hashed together with the User Agent to generate a <code>request_id</code>, which is saved. The User Agent is saved as well but cannot be connected to the user since the user IP is never saved.</p>
        <p>This information aims to inform you, the user of Podlove Publisher.</p>
        <h2>IP Addresses</h2>
        <p>If you are using Podlove Publisher Tracking/Analytics, an update to version 2.7.5 or higher is recommended.</p>
        <p>Tracking uses a <code>request_id</code> to be able to determine when two requests came from the same user and should be counted as one unique access. This <code>request_id</code> is a has made of the request IP address and user agent. Unfortunately it is not possible to anonymize IP addresses without skewing download statistics. However, since the request IDs are only used for grouping in the same hour, they can be anonymized after a day without losing tracking accuracy. Once a day, all <code>request_id</code>s older than 24 hours are salted in a way that makes it impossible to restore the original IP address.</p>
        <p>Second, you need to deal with the existing <code>request_id</code>s. There is a new &quot;DSGVO&quot; section under &quot;Tools&quot; with a button that will rehash all existing <code>request_id</code>s with a randomly generated salt. That way it will become unfeasible to determine the original IP address but your analytics will stay the same.</p>
        <p>In case you have a lot of downloads (let&#39;s say much more than 50.000), you may want to do this via command line because that will be much quicker than via the tools section. You need <a href="https://wp-cli.org/" target="_blank" rel="noreferrer">wp-cli</a>, then simply call <code>wp eval &#39;podlove_rehash_tracking_request_ids();&#39;</code>. On a multisite, pass the blog id as a parameter: <code>wp eval &#39;podlove_rehash_tracking_request_ids(42);&#39;</code>.</p>
        <h2>Text Snippets for your Privacy Page</h2>
        <p>Feel free to copy the following text into your sites disclaimer to inform your users. Remove the sections that don&#39;t apply to you if you&#39;re not using some module.</p>
        <p>This is not legal advice.</p>
        <p>If you have improvement suggestions for these texts, please let us know in the forums: community.podlove.org.</p>
        <h3>Podlove</h3>
        <p>Both <strong>Podlove Subscribe Button</strong> and <strong>Podlove Web Player</strong> may be served via <a href="https://www.keycdn.com/" target="_blank" rel="noreferrer">keycdn.com</a> (<a href="https://www.keycdn.com/gdpr" target="_blank" rel="noreferrer">keycdn GDPR statement</a>).</p>
        <p>Podlove Publisher tracks download statistics. IP adresses are used to determine an estimated geographic location (city or state level).</p>
        <p>IP addresses are stored temporarily (up to 48 hours) as part of a request id. This is necessary because the podcast owner needs to know how often episodes are downloaded to prove the viability of her/his endeavours.</p>
        <p>To determine a realistic download number, the system must be able to recognise repeated access to the same file by the same user. The only reliable way to achieve this is by considering the IP address in combination with the User Agent. Using an anonymised IP address is not possible because it would lead to wrong results. An access to the same file by the same user on different days can be considered separate downloads, therefore it is necessary to store IPs for only up to 48 hours.</p>
        <p>After 48 hours request ids are salted in a way that makes it impossible to restore the original IP address.</p>
        <p>The User Agent is stored as well.</p>
        <p></p>
      </div>
    </section>
  </Layout>
)

export default PrivacyScreen
